.description-container {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.description-text {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.survey-container {
	background-color: rgba(242, 244, 247, 1);
	padding: 10px;
	border-radius: 10px;
	margin: 10px 0px;
}

.survey-total {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: 0.125rem 0.625rem 0.25rem;
	border-radius: 9999px;
	background-color: #38a169;
	color: white;
	width: max-content;
	margin-bottom: 10px;
}

.survey-point {
	margin-bottom: 10px;
}
