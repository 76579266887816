.container-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  color: #6941c6;
  text-align: center;
}

.subtext {
  color: #98a2b3;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  max-width: 40rem;
  margin-top: 8px;
}

.bold-text {
  color: #667085;
  font-weight: bold;
}

.content-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 64rem;
  padding: 2rem;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.image {
  width: 20rem;
  border-radius: 0.5rem;
}

.questions-container {
  width: 100%;
}

.question-item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
}

.question-circle {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 50%;
}

.question-text {
  color: #344054;
  font-weight: 400;
  font-size: 20px;
  width: 80%;
  text-wrap: pretty;
}

.gradient-blue-purple {
  background: linear-gradient(to right, #54CDF2, #6941C6);
}

.gradient-pink-yellow {
  background: linear-gradient(to right, #F321D7, #FFEC61);
}

.gradient-purple-green {
  background: linear-gradient(to right, #9A4EFF, #24FF72);
}

.gradient-blue-yellow {
  background: linear-gradient(to right, #5B59AE, #FFEC61);
}

.checkbox-container {
  margin-top: 24px;
  font-size: 16px;
  color: #344054;
  font-weight: 500;
}

.button-container-pre-existing{
  margin-top: 16px;
  width: 590px;
  display: flex;
  align-items: flex-end;
  gap: 12px;
}

.back-button-pre {
  background-color: #eaecf0;
  color: #53389e;
  border-radius: 9999px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5rem;
}

.proceed-button-pre {
  border-radius: 9999px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5rem;
}

.proceed-button-pre:disabled {
  background-color: #eaecf0 !important;
  color: #53389e !important;
  border: none !important;
  cursor: not-allowed;
}