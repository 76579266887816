.aitab-menu-container {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 12px;
	background-size: 400% 400%;
	-webkit-animation: BgAnimaiton 18s ease infinite;
	-moz-animation: BgAnimaiton 18s ease infinite;
	animation: BgAnimaiton 18s ease infinite;
	color: var(--text-color);

	.aitab-menu-title {
		font-size: 18px;
		font-weight: bold;
	}

	.aitab-menu-item {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		padding-left: 8px;
		padding-right: 8px;
		border-radius: 6px;
		color: var(--text-color);
		cursor: pointer;
	}

	.selected-ai-tab {

		.stroke-white,
		.text-\[\#ffffff55\] {
			opacity: 100;
			color: white !important;
			stroke: white !important;
		}
	}

	.selected-ai-tab {
		border: 1px solid #f9fafb;
		padding: 1px;
		padding-left: 8px;
		padding-right: 8px;
		border-radius: 8px;
		color: white;
	}

	.unselected-ai-tab:hover {
		background-color: var(--submenu-item-active);
		color: white;

		.stroke-white,
		.text-\[\#ffffff55\] {
			opacity: 100;
			color: white !important;
			stroke: white !important;
		}
	}

	.aitab-menu-divider {
		color: #ffffff55;
	}
}
