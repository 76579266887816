.report-survey-painlevel {
	display: flex;
	gap: 0.5rem;
}

.report-survey-painlevel-label {
	font-size: 0.875rem /* 14px */;
	line-height: 1.25rem;
	font-weight: 600;
	padding-top: 0.4rem;
}

.text-rose-600 {
	--tw-text-opacity: 1;
	color: rgb(227 27 84 / var(--tw-text-opacity)) /* #e31b54 */;
}

.text-rose-500 {
	--tw-text-opacity: 1;
	color: rgb(246 61 104 / var(--tw-text-opacity)) /* #f63d68 */;
}

.text-orange-500 {
	--tw-text-opacity: 1;
	color: rgb(239 104 32 / var(--tw-text-opacity)) /* #ef6820 */;
}

.text-orange-400 {
	--tw-text-opacity: 1;
	color: rgb(243 135 68 / var(--tw-text-opacity)) /* #f38744 */;
}

.text-yellow-400 {
	--tw-text-opacity: 1;
	color: rgb(250 197 21 / var(--tw-text-opacity)) /* #fac515 */;
}

.text-green-300 {
	--tw-text-opacity: 1;
	color: rgb(115 226 163 / var(--tw-text-opacity)) /* #73e2a3 */;
}

.text-cyan-500 {
	--tw-text-opacity: 1;
	color: rgb(6 174 212 / var(--tw-text-opacity)) /* #06aed4 */;
}

.report-survey-summary {
	margin-top: 1.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-align: center;
	color: #4b5563;
}

.report-survey-container {
	padding-bottom: 1.5rem;
	border-bottom-width: 2px;
}

.report-survey-date {
	margin-top: 0.75rem;
	font-weight: 600;
	color: #4b5563;
}

.report-survey-summary-container {
	display: flex;
	margin-top: 0.625rem;
	margin-bottom: 0.625rem;
	margin-top: 0.25rem;
	flex-direction: column;
	gap: 0.5rem;
	border-radius: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: #6b7280;
	background-color: #f3f4f6;
	padding: 15px;
}

.report-survey-list {
	display: flex;
	gap: 0.5rem;
	justify-content: space-between;
	align-items: center;
}

.report-survey-inner-list {
	display: flex;
	gap: 0.5rem;
}

.report-survey-title {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	color: #111827;
}

.report-score-container {
	display: flex;
	gap: 0.5rem;
	justify-content: flex-end;
	align-items: center;
}

.report-score-label {
	display: flex;
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-bottom: 0.25rem;
	gap: 0.25rem;
	align-items: center;
	border-radius: 9999px;
	height: fit-content;
	color: #ffffff;
	background-color: #12b76a;
}

.report-survey-question-score {
	display: flex;
	padding-top: 0.125rem;
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	gap: 0.25rem;
	align-items: center;
	border-radius: 9999px;
	height: fit-content;
	color: #ffffff;
	background-color: #12b76a;
}

.report-survey-question {
	height: 2rem;
	font-weight: 700;
	line-height: 1.25rem;
}

.report-survey-question-inner-container {
	display: flex;
	justify-content: space-between;
}

.report-survey-question-container {
	margin-bottom: 0.5rem;
	border-radius: 0.375rem;
	background-color: #f9fafb;
	padding: 15px;
}
