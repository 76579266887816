.estimator-loading {
	width: 300px;
	height: 300px;
	margin-top: -150px;
	margin-left: -150px;
	position: absolute;
	color: white;
	top: 50%;
	left: 50%;
}
