@import './style.less';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

/* overwrite antdesign default fonts */
body {
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

#root,
.ant-modal-wrap {
	div,
	span,
	p,
	ul,
	li,
	button,
	strong,
	ol {
		font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	}
}

html,
body,
#root {
	height: 100%;
	background: #f5f5f5;
}

// @media screen and (orientation: portrait) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     height: 100vw;
//     overflow-x: hidden;
//     position: fixed;
//     top: 100%;
//     left: 0;
//   }

//   body {
//     overflow: auto;
//   }

//   .admin-menu-content {
//       height: 100vw;
//   }
// }

.layout {
	background-color: rgb(255, 255, 255);
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: auto;
	height: 100%;
}

.default-layout-admin > .content {
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.default-layout-admin .sidebar-admin {
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.ui.form textarea,
.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'] {
	background: #f5f5f7 !important;
	border-color: white !important;
}

.ui.icon.button > .icon,
.ui.icon.buttons .button > .icon {
	margin: 0px 0 0 1px !important;
}

.alink {
	color: #4183c4;
	text-decoration: none;
	border: 0;
	background: transparent;
	cursor: pointer;
	box-sizing: inherit;
}

.hotspot-circle.active:after {
	top: -2px !important;
	left: -2px !important;
}

.custom-ant-table .ant-table-thead > tr > th {
	text-transform: uppercase;
	font-size: 0.8em;
	color: rgba(0, 0, 0, 0.255);
	font-weight: 700;
}

.custom-ant-table .ant-table-thead > tr > th,
.custom-ant-table .ant-table-tbody > tr > td,
.custom-ant-table .ant-table tfoot > tr > th,
.custom-ant-table .ant-table tfoot > tr > td {
	padding: 12px 16px;
}

h1.custom-title {
	font-size: 16px;
	font-weight: 700;
	margin: 40px 0 10px 0;
}

h1.custom-subtitle {
	font-size: 14px;
	font-weight: 700;
	margin: 20px 0 0;
}

.carespace-coach .instruction-video {
	width: 100%;
}

.btn-outline.btn-close,
.btn-outline.btn-close-compare {
	padding: 5px !important;
}

.pre-assessment .container {
	margin-top: 20px;
}

.video-item video.video {
	max-width: 300px;
}

.tag-pt,
.tag-user {
	padding: 2px 6px;
	position: absolute;
	top: 5px;
	color: #fff;
	border-radius: 7px;
	right: 14px;
	font-size: 13px;
	font-weight: 700;
}

.tag-pt {
	background: #6b2ba0;
}

.tag-user {
	background: #01c752;
}

button.ant-btn.ant-btn-default.btn-purple {
	background: #6739b7;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #fff;
	margin: 8px 0;
	border-radius: 6px;
	border: 2px solid #6739b7;
	cursor: pointer;
}

button.ant-btn.ant-btn-default.btn-purple[disabled] {
	background: #4d346c75;
	color: #fcfbfe2b;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	margin: 8px 0;
	border-radius: 6px;
	border: 2px solid #6739b7;
	cursor: default;
}

.record-video-btn.btn-purple {
	float: right;
}

button.ant-btn.ant-btn-default.btn-purple.record-video-btn:hover {
	background: transparent;
	color: #6739b7;
	border-color: #6739b7;
}

.carespace-coach .ant-breadcrumb ol {
	justify-content: center;
}

.carespace-coach span.ant-breadcrumb-link {
	color: #fff;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
	font-size: inherit;
	color: currentColor;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
	padding: 5px 0;
	margin-top: -5px;
}
@media screen and (min-width: 3000px) {
	.carespace-body {
		zoom: 250%;
	}
}

.dropdown-badge button {
	position: relative;
	top: 2px;
}

.ant-select-dropdown {
	width: auto !important;
}

.rc-virtual-list-holder-inner {
	position: relative !important;
}

.ant-card-small > .ant-card-body {
	padding: 0px;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
	color: #ffffff;
	padding: 20px;
	font-size: 18px;
}
.ant-message
	.ant-message-notice-wrapper
	.ant-message-notice-success
	.ant-message-notice-content {
	background: #01c752;
}
.ant-message
	.ant-message-notice-wrapper
	.ant-message-notice-error
	.ant-message-notice-content {
	background: #e31b54;
}
.ant-message
	.ant-message-notice-wrapper
	.ant-message-notice-info
	.ant-message-notice-content {
	background: #1677ff;
}
.ant-message
	.ant-message-notice-wrapper
	.ant-message-notice-warning
	.ant-message-notice-content {
	background: #faad14;
}
.ant-message
	.ant-message-notice-wrapper
	.ant-message-notice-loading
	.ant-message-notice-content {
	background: #faad14;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-content .anticon {
	color: #ffffff;
	font-size: 21px;
}

.ant-spin .ant-spin-dot-item {
	background-color: var(--loader-bg-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: var(--adminmenu-bg-color);
	border-color: var(--adminmenu-bg-color);
}

.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active:hover a {
	color: var(--mainmenu-bg-color)!important;
}

.ant-pagination .ant-pagination-jump-next{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next:hover .ant-pagination-item-link-icon
{
	color: var(--text-color)
}

.ant-tooltip.ant-tooltip-purple .ant-tooltip-inner {
	background-color: var(--mainmenu-bg-color);
}

// .ant-tooltip.ant-tooltip-purple .ant-tooltip-arrow {
// 	--antd-arrow-background-color: var(--mainmenu-bg-color);
// }

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
	color: var(--mainmenu-bg-color);
	border-color: var(--mainmenu-bg-color);
	background: #ffffff;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--mainmenu-bg-color);
	text-shadow: 0 0 0.25px var(--mainmenu-bg-color);
}

.ant-btn-primary {
	color: #fff;
	background: var(--mainmenu-button-default-color);
	box-shadow: 0 2px 0 var(--mainmenu-button-default-color);
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
	color: #fff;
	background: var(--mainmenu-button-default-color);
}

.ant-btn:hover {
	background: var(--mainmenu-button-default-color);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
	.ant-checkbox-checked:not(.ant-checkbox-disabled)
	.ant-checkbox-inner {
	background-color: var(--mainmenu-bg-color);
	border-color: transparent;
}

.ant-tabs .ant-tabs-ink-bar {
	background: var(--mainmenu-bg-color);
}

.menu-dropdown .ant-dropdown {
	position: absolute;
	left: 80px;
}

.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within {
	border-color: var(--mainmenu-bg-color);
}

.ant-tabs .ant-tabs-tab:hover {
	color: var(--mainmenu-bg-color);
}

.ant-empty-normal .ant-empty-description {
    color: var(--gray-600);
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
	color: var(--mainmenu-bg-color);
}

.custom-space-dropdown .ant-space-gap-col-small {
	column-gap: 4px;
}

.custom-space-dropdown{
	box-shadow: none!important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
	border-color: #fff;
	background-color: var(--menu-item-active);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title{
	padding-inline-end: 2px;
}

:root {
	--wellness-button-color:#9E77ED;
	--mainmenu-bg-color: #6941c6;
	--mainmenu-button-default-color: #6941c6;
	--loader-bg-color: #6941c6;
	--button-color:#BDFF00;
	--button-color-primary: #6941c6;
	--text-color: #6941c6;
	--text-color-primary-to-black: #6941c6;
	--text-color-root: #000;
	--p-text-color-root: #fff;
	--button-text-color:#6941c6;
	--activity-card-bg-color:#fff;
	--activity-active-side: #6941c6;
	--video-bg-text:#344054;
	--recommendation-bg-color: #fff;
	--collapse-bg-color: #f9fafb;
	--layout-bg-color: #fff;
	--home-bg-color: #eee;
	--card-bg-color: #fff;
	--mainmenu-bg-color-player-bg: #6941c6d9;
	--adminmenu-bg-color: #53389e;
	--menu-item-active: #7f56d9;
	--submenu-item-active: #7f56d9;
	--status-count: #6941c6;
	--user-menu-bg-color: #5c3eaa;
	--user-menu-dropdown-bg-color: #42307d;
	--function-goal-icon-non-selected: #6541b7;
	--ai-dropdown: #5c3eaa;
	--activity-footer-text: #4e5ba6;
	--survey-selected-bg: #e9d7fe;
	--survey-selected-border: #b692f6;
	--survey-unselected-bg: #f4ebff;
	--fixed-footer-bg-color:#f3f4f6;
	--default-bg-tab-color: linear-gradient(
		270deg,
		#7f56d9,
		#6342b2,
		#647ad4,
		#71b0ff,
		#55389e
	);
	--primary-25: #fcfaff;
	--primary-50: #f9f5ff;
	--primary-100: #f4ebff;
	--primary-200: #e9d7fe;
	--primary-300: #d6bbfb;
	--primary-400: #b692f6;
	--primary-500: #9e77ed;
	--primary-700: #6941c6;
	--primary-800: #53389e;
	--primary-900: #42307d;
	--primary-DEFAULT: #9e77ed;
		--gray-25: #fcfcfd;
	--gray-50: #f9fafb;
	--gray-100: #f2f4f7;
	--gray-200: #eaecf0;
	--gray-300: #d0d5dd;
	--gray-400: #98a2b3;
	--gray-500: #667085;
	--gray-600: #475467;
	--gray-700: #344054;
	--gray-800: #1d2939;
	--gray-900: #101828;
	--gray-default: #667085; /* Same as 500 */
	--text-gray:#6b7280;
}

/* Harley Davidson Theme */
[data-theme='vibrant'] {
	@dark-color: #000;
	@light-color:#FFF;
	@accentuated-color: #ff6600;
	@dark-grey-color: #0d0f0f;
	@light-grey-color: #475467;

	--mainmenu-bg-color: @dark-color;
	--mainmenu-button-default-color: @dark-color;
	--loader-bg-color: @accentuated-color;
	--button-color:@accentuated-color;
	--button-color-primary: @dark-color;
	--text-color: @dark-color;
	--text-color-primary-to-black: @dark-color;
	--text-color-root: @dark-color;
	--p-text-color-root: #fff;
	--button-text-color:#fff;
	--activity-card-bg-color:#fff;
	--activity-active-side: @dark-color;
	--video-bg-text:#344054;
	--recommendation-bg-color: #fff;
	--collapse-bg-color: #f9fafb;
	--layout-bg-color: #fff;
	--home-bg-color: #eee;
	--card-bg-color: #fff;
	--mainmenu-bg-color-player-bg: fade(@dark-color, 85%);
	--adminmenu-bg-color: @dark-grey-color;
	--menu-item-active: @accentuated-color;
	--submenu-item-active: @accentuated-color;
	--status-count: @dark-color;
	--user-menu-bg-color: @dark-color;
	--user-menu-dropdown-bg-color: @dark-grey-color;
	--antd-arrow-background-color: @dark-grey-color;
	--function-goal-icon-non-selected: #222525;
	--ai-dropdown: #5c3eaa;
	--activity-footer-text: #4e5ba6;
	--survey-selected-bg: #e9d7fe;
	--survey-selected-border: #b692f6;
	--survey-unselected-bg: #f4ebff;
	--fixed-footer-bg-color:#f3f4f6;
	--default-bg-tab-color: linear-gradient(
		270deg,
		#7f56d9,
		#6342b2,
		#647ad4,
		#71b0ff,
		#55389e
	);
	--primary-25: lighten(@accentuated-color, 45%);  
	--primary-50: lighten(@accentuated-color, 40%);
	--primary-100: lighten(@accentuated-color, 30%);
	--primary-200: lighten(@accentuated-color, 20%);
	--primary-300: lighten(@accentuated-color, 10%);
	--primary-400: saturate(@accentuated-color, 5%);  
	--primary-500: @accentuated-color;                
	--primary-600: darken(@accentuated-color, 10%);
	--primary-700: darken(@accentuated-color, 20%);
	--primary-800: darken(@accentuated-color, 30%);
	--primary-900: darken(@accentuated-color, 40%);  
	--primary-DEFAULT: @accentuated-color;

	--gray-25: #fcfcfd;
	--gray-50: #f9fafb;
	--gray-100: #f2f4f7;
	--gray-200: #eaecf0;
	--gray-300: #d0d5dd;
	--gray-400: #98a2b3;
	--gray-500: #667085;
	--gray-600: #475467;
	--gray-700: #344054;
	--gray-800: #1d2939;
	--gray-900: #101828;
	--gray-default: #667085; /* Same as 500 */
	--text-gray:#6b7280;

	img[src*="tick_mark.svg"] {
		filter: 
		invert(10%)       
		sepia(99%)        
		hue-rotate(15deg) 
		saturate(2476%)   
		brightness(103%)  
		contrast(103%); 
	}
}

/* Dark Theme */
[data-theme='dark'] {
	--mainmenu-bg-color: #000000;
	--activity-card-bg-color:#000;
	--mainmenu-button-default-color: #000;
	--loader-bg-color: #99d000;
	--video-bg-text:#000;
	--button-color-primary: #000;
	--button-text-color: #000;
	--collapse-bg-color: #000000;
	--p-text-color-root: #000;
	--black-700: #f5f5f5;
	--text-color-primary-to-black: #000;
	--layout-bg-color: #000000;
	--home-bg-color: #000;
	--card-bg-color: #3b3c3e;
	--wellness-button-color:#333;
	--mainmenu-bg-color-player-bg: #000000d9;
	--adminmenu-bg-color: #3b3c3e;
	--ai-dropdown: #333;
	--activity-footer-text: #333;
	--menu-item-active: #6e6d6d;
	--fixed-footer-bg-color:black;
	--submenu-item-active: #666;
	--status-count: #888;
	--user-menu-bg-color: #080808;
	--user-menu-dropdown-bg-color: #212121;
	--function-goal-icon-non-selected: #888;
	--survey-selected-bg: #252525;
	--survey-selected-border: #888;
	--survey-unselected-bg: #666;
	--default-bg-tab-color: #3b3c3e;
	--recommendation-bg-color: #212121;
	--text-color: white;
	--primary-25: #252525;
	--primary-50: #333;
	--primary-100: #444;
	--primary-200: #555;
	--primary-300: #9a9a9a;
	--primary-400: #a2a2a2;
	--primary-500: #888;
	--primary-700: #3a3939;
	--primary-800: #fff;
	--primary-900: #bbb;
	--primary-default: var(--primary-500);
	--gray-25: #101828; /* Previously 900 */
	--gray-50: #f9fafb; /* Previously 800 */
	--gray-100: #344054; /* Previously 700 */
	--gray-200: #98a2b3; /* Previously 600 */
	--gray-300: #667085; /* Previously 500 */
	--gray-400: #98a2b3; /* Previously 400 (same) */
	--gray-500: #fff; /* Previously 300 */
	--gray-600: #667085; /* Previously 200 */
	--gray-700: #98a2b3; /* Previously 100 */
	--gray-800: #f9fafb; /* Previously 50 */
	--gray-900: #000; /* Previously 25 */
	--gray-default: #d0d5dd; /* Previously 500, now 300 */
	--text-color-root: #fff;
	--text-gray:#6b7280;
	@btn-default-color: #fff;
}

.as-panel.ant-collapse > .ant-collapse-item > .ant-collapse-header{
	display: flex;
	align-items: center;
}

.ant-btn-primary {
	color: #fff;
	background: var(--button-color-primary);
	box-shadow: 0 2px 0 var(--button-color-primary);
}

.theme-card .ant-card-body {
	padding: 8px;
}
