.medical-container {
  text-align: center;
  margin-top: 16px;
}

.medical-subcontainer {
  width: 627px;
  display: inline-block;
}

.content-div-associated {
  height: 620px;
  overflow: auto;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap; 
  gap: 20px; 
}