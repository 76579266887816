.pre-assessment {
	background: rgb(166, 126, 255);
	background: linear-gradient(
		130deg,
		rgb(100 53 205 / 95%) 0%,
		rgb(87 94 189 / 95%) 100%
	);

	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	opacity: 1;
	top: 0;
	z-index: 1000;
	overflow-y: auto;
}
.pre-assessment .containers {
	padding-top: 35px;
}
.body-shape-front,
.body-shape-back {
	display: inline-block;
	position: relative;
	margin-bottom: 30px;
}
.hotspot-circle {
	width: 20px;
	height: 20px;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	position: absolute;
	cursor: pointer;
}
.hotspot-circle:hover {
	background: #ff00ba;
}
.hotspot-circle.active {
	background: #ff00ba;
}
.hotspot-circle.saved {
	background: #00ff2b;
}
.hotspot-circle.active:after {
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 3px solid #ff00ba;
	position: absolute;
	top: -5px;
	left: -5px;
	animation: pulse 2s ease 0s infinite;
}
@keyframes pulse {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	80% {
		opacity: 0;
		transform: scale(2);
	}
	100% {
		opacity: 0;
		transform: scale(3);
	}
}
.pre-assessment h1,
.pre-assessment h2,
.pre-assessment .tooltiptext {
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	color: #fff;
	text-align: center;
}
h1.pre-assessment-title {
	font-weight: 700;
	font-size: 23px;
}
h2.pre-assessment-subtitle {
	font-weight: 300;
	margin-top: 42px;
	font-size: 20px;
}
.tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #ff01ba;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 139%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
}
.tooltiptext::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #ff01ba transparent transparent transparent;
}

.hotspot-circle:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
.popup-questions {
	width: 320px;
	background: #fff;
	position: absolute;
	top: 60px;
	border-radius: 15px;
	padding: 20px 25px;
}
.popup-questions.right {
	right: 20px;
}
.popup-questions.left {
	left: 20px;
}
.popup-header h1 {
	color: #673ab7;
	font-size: 15px;
	text-align: left;
	text-transform: uppercase;
	margin-top: 0;
}
.css-qc6sy-singleValue {
	color: hsl(0deg 0% 48%);
}
.btn-close-popup {
	border-radius: 50%;
	padding: 0;
	border: none;
	color: #6823c0;
	text-transform: uppercase;
	cursor: pointer;
	background: transparent;
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	top: 8px;
	right: 10px;
}
.invalid,
.invalid .css-1s2u09g-control {
	border-color: #ff01ba !important;
}
.range-slider.invalid input#myRange {
	background: #ff01ba !important;
}
.range-slider.invalid .sliderValue {
	color: #ff01ba !important;
}
.error-message {
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: 500;
	color: #ff01ba;
	text-align: center;
}

.pain-assessment-modal .ant-modal-content .ant-modal-body {
	padding: 0;
}
.pain-assessment-modal button.ant-modal-close {
	color: #fff;
	z-index: 10000;
}
.pain-assessment-modal .pre-assessment {
	height: 90vh;
	min-height: 100%;
	position: relative;
}
