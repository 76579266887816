.pain-status-container {
	text-align: center;
	margin-top: 16px;
}

.pain-subcontainer {
	display: flex;
	gap: 20px;
	justify-content: center;
}

.flip-container {
	position: relative;
	perspective: 1000px;
}

.flip-container-front {
	box-shadow: 0 0 10px rgba(184, 184, 184, 0.5);
	position: relative;
	border-radius: 20px;
	height: 520px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: transform 0.6s ease;
	transform-style: preserve-3d;
	cursor: pointer;
}

.flip-container-front-title {
	position: absolute;
	width: 100%;
	bottom: 0;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	height: 84px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 18px;
	text-align: center;
	padding: 20px;
	font-weight: 300;
}

.flip-container-back {
	box-shadow: 0 0 10px rgba(184, 184, 184, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transform: rotateY(180deg);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	backface-visibility: hidden;
}

.flip-container-back-content {
	position: absolute;
	padding: 20px;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.flip-container .flip-container-front,
.flip-container .flip-container-back {
	backface-visibility: hidden;
}

.flip-container:hover .flip-container-front {
	transform: rotateY(-180deg);
}

.flip-container:hover .flip-container-back {
	opacity: 1;
	visibility: visible;
	transform: rotateY(0deg);
}

.flip-container-option {
	padding: 10px;
	border-bottom: 1px solid white;
	color: white;
}
