.mainTabs > .ant-tabs-nav {
	margin-bottom: 0;
}

.mainTabs .card-container p {
	margin: 0;
}

.mainTabs .card-container {
	padding: 12px;
}

.mainTabs .ant-collapse-content > .ant-collapse-content-box {
	padding: 0;
}

.mainTabs .ant-collapse-content > .ant-collapse-content-box > .ant-row {
	padding: 5px;
}

.mainTabs
	.card-container
	> .ant-tabs-card
	.ant-tabs-content
	> .ant-tabs-tabpane {
	padding: 2px;
	background: rgb(249 250 251);
}

.mainTabs .card-container > .ant-tabs-card > .ant-tabs-nav::before {
	display: none;
}

.mainTabs .card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact']
	.mainTabs
	.card-container
	> .ant-tabs-card
	.ant-tabs-tab {
	background: transparent;
	border-color: transparent;
}

.mainTabs .card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact']
	.mainTabs
	.card-container
	> .ant-tabs-card
	.ant-tabs-tab-active {
	background: rgb(249 250 251);
	border-color: rgb(249 250 251);
}

#components-tabs-demo-card-top .code-box-demo {
	padding: 24px;
	overflow: hidden;
	background: #f5f5f5;
}

[data-theme='compact']
	.mainTabs
	.card-container
	> .ant-tabs-card
	.ant-tabs-content {
	height: 120px;
	margin-top: -8px;
}

[data-theme='dark'] .mainTabs .card-container > .ant-tabs-card .ant-tabs-tab {
	background: transparent;
	border-color: transparent;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
	background: #000;
}

[data-theme='dark']
	.mainTabs
	.card-container
	> .ant-tabs-card
	.ant-tabs-content
	> .ant-tabs-tabpane {
	background: #141414;
}

[data-theme='dark']
	.mainTabs
	.card-container
	> .ant-tabs-card
	.ant-tabs-tab-active {
	background: #141414;
	border-color: #141414;
}

.card-container .ant-carousel .slick-slide img {
	display: block;
	max-width: 90%;
	margin: 0 auto;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.card-container svg {
	display: block;
}

.button svg {
	margin-left: 4px;
	margin-top: 3px;
}

.active-paragraph .custom-paragraph {
    max-width: 230px;
}

.custom-paragraph {
    max-width: 380px;
	margin: 0;
}

.edit-icon {
	fill: red;
}
.ant-table-cell div.ant-typography, .ant-table-cell .ant-typography p {
	margin-bottom: 0em; 
}
.ant-table-pagination-right {
	justify-content: center;
}

.fontStyles {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.titleStyle {
  color: var(--menu-item-active);
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.checkbox-tick svg {
	display: block;
}

.scroll::-webkit-scrollbar {
  height: 12px;
  width: 13px;
}
 
.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}
 
.scroll::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  border-radius: 100px;
  background-clip: content-box;
  background-color: rgba(219, 219, 219, 0.728);
}

.coach-rom-summary-container-row {
  background-color: #f8f9fa;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
	padding: 15px 5px 0 5px;
}

.coach-rom-summary-container-row-col {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
}

@media (max-width: 1280px) {
  .coach-rom-summary-container-row-col {
    flex-wrap: wrap;
  }
}

.coach-rom-summary-container-row-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
  width: 100%;
}

.coach-rom-summary-container-row-div-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.coach-rom-summary-container-row-div-h1 {
  font-weight: bold;
  font-size: 1.125rem;
	color: var(--text-color-root);
}

.create-report-content-icon-div {
	display: flex;
	align-items: center;
	gap: 0.5rem ;
	margin-bottom: 0.5rem;
}

.create-report-icon-styling {
	margin-bottom: 0.2rem;
}

.create-report-icon-span-css {
	padding-top: 0.75rem;
	padding-right: 1rem;
}

.create-report-item-content {
   align-items: center;
	 padding-bottom: 8px;
	 padding-top: 8px;
}
