.upload-bar-user-container {
    display: flex;
    justify-content: around;
    align-items: center;
    height: 200px;
    width: 100%;
    cursor: pointer;
  }
  
  .upload-bar-user-progress {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 8px;
    width: 100%;
  }
  
  .upload-bar-user-progress-wrapper {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center
  }
  
  .upload-bar-user-bar {
    display: flex;
    justify-content: center;
    width: 85%;
  }
  
  .upload-bar-user-text {
    color: black;
  }
  