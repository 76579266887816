.whole-day-container {
	height: 550px;
	width: 850px;
}

.whole-day-row-gap {
	gap: 2rem;
	margin-top: 1.25rem;
}

.spin-css {
	display: flex;
	align-items: center;
	height: 100vh;
	justify-content: center;
}

.spin-css-inbox .ant-spin-dot i {
  background-color: white;
}

.whole-day-card {
	height: 250px;
	background-color: #e5e7eb;
	border-radius: 1rem;
}

.whole-day-card.large-card {
	width: 260px;
}

.whole-day-card-content {
	height: 250px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.whole-day-title {
	margin-top: 0.5rem;
	font-family: 'Inter', sans-serif;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	text-align: left;
	background: linear-gradient(to right, #6941c6, #54cdf2);
	-webkit-background-clip: text;
	color: transparent;
}

.whole-day-description {
	font-family: 'Inter', sans-serif;
	margin-top: 0.5rem;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	background: linear-gradient(to right, #6941c6, #54cdf2);
	-webkit-background-clip: text;
	color: transparent;
}

.whole-day-slider {
	width: 85%;
}

.whole-day-btn-container {
	display: flex;
	margin-top: 0.5rem;
	padding-bottom: 10px;
	gap: 1rem;
	width: 900px;
	align-items: flex-end;
	width: max-content;
}

.whole-day-mt-2 {
	margin-top: 0.5rem;
}

.whole-day-header-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.whole-day-header-title {
	font-size: 25px;
	color: #6941c6;
	font-weight: 700;
}

.whole-day-header-subtitle {
	font-size: 18px;
	color: #98a2b3;
	font-weight: 300;
}

.whole-day-content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}


/* Overall container */
.activity-ring-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;
}

/* Main content layout */
.activity-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  width: 100%;
  max-width: 800px;
}

/* Slider section */
.sliders-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.slider-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activity-label {
  font-weight: 500;
  font-size: 16px;
  margin-right: 16px;
}

.activity-slider {
  flex: 1;
  max-width: 70%;
}

/* Ring chart section */
.ring-chart-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
