.ai-assistant-search-bar {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background: var(--default-bg-tab-color);
	background-size: 400% 400%;
	animation: BgAnimaiton 18s ease infinite;
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.menu-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 100%;
  height: 36px;
  padding-left: 10px;
}

.menu-text {
  font-size: 0.875rem; 
  font-weight: 500;
  color: white;
}

.menu-icon {
  display: inline-block;
  margin-bottom: 3px; 
}

@keyframes BgAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.menu-toggle-container {
  position: relative; 
  display: inline-block;
}

.custom-dropdown-container {
  margin-top: 10px;
  position: absolute;
  z-index: 105; 
  width: max-content;
  min-width: 200px;
}

.input-ai-searcbar {
  width: 95%;
  margin-left: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  background-color: var(--ai-dropdown);
  border: none;
  outline: none;
  margin-top: 12px;
  height: 35px;
  padding-left: 20px;
  color: white;
}

.input-ai-searcbar::placeholder {
  color: white;
  opacity: 1; 
}

.input-ai-searcbar:focus {
  outline: none;
  border: none;
}
.custom-dropdown-search-content {
  margin-top: 10px;
  margin-left: 20px;
  width: 400px;
  border-radius: 16px;
  max-height: 500px;
  overflow: auto;
  background-color: var(--user-menu-dropdown-bg-color);
  .filtered-item-icon-css:hover {
    .text-white, .stroke-white{
      color: #bdff00 !important;
      stroke: #bdff00 !important;
    }
  }

  .search-suggestion-div {
    align-items: center;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    color: #98a2b3;
  }

  .search-label {
    font-size: 14px;
    cursor: default;
    font-weight: 600;
    margin-left: 16px;
  }

  .recent-search-div {
    align-items: center;
    padding-bottom: 0.25rem;
    padding-top: 0.75rem;
    color: #98a2b3;
  }

  .recent-search-close-icon {
    padding-right: 1rem;
    margin-left: 1.25rem;
    cursor: pointer;
    display: grid;

    .recent-item-text {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0.5rem;
      margin-left: 8px;
      color: #FFFFFF
    }

    .recent-item-text:hover {
      color: rgb(189, 255, 0);
    }
  }

  .filtered-data-div {
    border-top-width: 1px;
    border-color: #667085;
    padding-top: 0.25rem;
  }

  .filtered-sub-container {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    display: grid;
    color: #98a2b3;
  }

  .filtered-item-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.25rem;
    cursor: default;
    margin-left: 16px;
    color: #ffffff90
  }

  .filtered-item-icon-css {
    padding-top: 0.75rem;
    padding-right: 1rem;
    margin-left: 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: start;
    transition: color 0.3s ease;

    &:hover {
      color: rgb(189, 255, 0); 
    }

    .list-name-label {
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;
      margin-left: 8px;
      color: #FFFFFF;
      transition: color 0.3s ease;
    }

    &:hover .list-name-label {
      color: rgb(189, 255, 0); 
    }
  }
}

.no-component-found-div {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: #6941c6;
  margin-left: 2rem;
  margin-top: 2.5rem;
}
