.code-label {
	padding: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-align: center;
	color: #4b5563;
}

.input-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.input-label {
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 400;
	color: #5e697a;
}

.input-label-container {
	display: inline-block;
	width: 20%;
	padding: 5px;
}

.input-right-label-container {
	display: inline-block;
	width: 80%;
	padding: 5px;
}

.code-container {
	background-color: #F2F4F7;
	margin-bottom: 5px;
	border-radius: 8px 8px 0 0;
}

.custom-margin-icd {
	margin-top: 5px;
}

.custom-padding {
	padding: 5px 0 5px 0;
}

