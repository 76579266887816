.medical-container {
	text-align: center;
	margin-top: 16px;
	margin-bottom: 16px;
}

.pain-assessment .pre-assessment {
	background: none;
}

.pain-assessment .hotspot-circle {
	background: #ccc;
}

.pain-assessment .pre-assessment .containers {
	min-width: 480px;
}

.pain-assessment .hotspot-circle.active {
	background: #ff00ba;
}
.pain-assessment .hotspot-circle.saved {
	background: #00ff2b;
}

.pain-assessment .pre-assessment h2 {
	color: #7f56d9;
}

.pain-assessment svg {
	display: block;
}
