.setting-title {
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 600;
	color: rgb(16 24 40 / var(--tw-text-opacity));
}
.main-container-setting-modal {
	font-weight: 400;
	font-size: 16px;
	color: rgb(71 84 103 / var(--tw-text-opacity));
}

.custom-quill .ql-editor {
	min-height: 250px;
}

.settings-templates-reset-to-default {
	color: #7a5af8;
	cursor: pointer;
}

.settings-templates-reset-to-default:hover {
	color: #b19dff;
}

.range-picker {
	height: 450px;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;

  .ant-picker-dropdown .ant-picker-panel-container {
    box-shadow: none;
  }

	.ant-picker {
		opacity: 0;
    height: 0px;
	}
}
