#root {
	.start-survey-row .no-button-style {
		background-color: rgb(220 38 38);
		color: white;
		border: inherit;
	}
	.start-survey-row .no-button-style:hover {
		background-color: rgb(220 38 38);
		color: white;
		border: inherit;
	}
}

.start-survey {
	.spinner {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px;
	}

	.start-survey-row {
		.custom-container-survey {
			margin-left: 0.75rem;
			border-width: 4px;
			border-radius: 0.75rem;
			border-color: #e5e7eb;
			display: flex;
			gap: 0.75rem;
			padding: 0 0.5rem;

			.custom-image-container {
				width: 11rem;
				display: flex;
				align-items: center;
				justify-content: center;

				.image {
					border-radius: 0.5rem;
					width: 100%;
					height: 9rem;
				}
			}

			.survey-container {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 1rem;

				.survey-title {
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					font-size: 1.125rem;
					line-height: 1.75rem;
					--tw-text-opacity: 1;
					color: rgb(52 64 84 / var(--tw-text-opacity));
				}

				.questions {
					--tw-text-opacity: 1;
					color: rgb(152 162 179 / var(--tw-text-opacity));
				}

				.description {
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					--tw-text-opacity: 1;
					color: rgb(102 112 133 / var(--tw-text-opacity));
					margin-top: 0.5rem;
				}

				.ant-btn .survey-button {
					display: flex;
					align-items: center;
					justify-content: center;
					color: rgb(255, 255, 255);
					margin-top: 0.5rem;
					border-radius: 0.5rem;
					width: 100%;
					border-style: none;

					.icon {
						--tw-text-opacity: 1;
						color: rgb(255 255 255 / var(--tw-text-opacity));
						width: 1rem;
						height: 1rem;
					}
				}

				.non-finished-survey-css {
					background-color: #bdff00 !important;
				}
			}
		}
	}
}

