.posture-captures {
	padding-bottom: 2.5rem;
	margin: 1.5rem;

	.ai-response {
		display: flex;
		margin-top: 1.25rem;
		gap: 9px;
		justify-content: flex-start;
		align-items: flex-start;

		.icon {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 0.375rem;
			padding-right: 0.375rem;
			border-radius: 9999px;
			background-color: rgb(244 235 255);
		}

		.content {
			width: 100%;

			.ai-assistant {
				font-weight: 700;
			}

			.description {
				color: rgb(102 112 133);
			}
		}
	}

	.tabs {
		margin-top: 15px;
	}
}

.posture-capture-data {
	margin-top: 20px;
	margin-bottom: 20px;

	.tab {
		background-color: #F9FAFB;

		.chart {
			margin-top: 40px;

			.chart-tool-tip {
				padding: 10px;

				.image {
					text-align: center;
					display: flex;
					justify-content: center;
					margin-bottom: 10px;
				}

				.date {
					font-weight: bold;
					margin-top: 10px;
				}

				.value {
					margin: 2px 0;
				}
			}
		}
	}
}
