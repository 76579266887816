.upload-progress-container {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	padding: 20px;
	gap: 10px;
}

.upload-message {
	font-size: 1.5em;
	margin-bottom: 20px;
	color: #ffffff;
	text-align: center;
}

.upload-progress-bar .ant-progress-bg {
	border-radius: 6px;
	text-align: center;
	transition: width 0.5s ease;
}

.upload-progress-bar .ant-progress-outer {
	width: 100%;
	max-width: 500px;
}

.progress-percentage {
	font-size: 1.2em;
	color: #ffffff;
	margin-top: 10px;
	font-weight: bold;
	transition: color 0.9s ease;
}

.progress-percentage::after {
	font-size: 0.8em;
	color: #ffffff;
}
