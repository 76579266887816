.centered-select-none {
  user-select: none;
  text-align: center;
  margin-top: 10%;
}

.non-selectable {
  margin-top: 24px;
  user-select: none;
}

.program-container-div {
  max-width: 1390px;
  padding: 15px;
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  overflow: hidden;
  flex-direction: column;
}

.program-inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.start-date {
  font-weight: 600;
  margin-right: 0.25rem;
}

.custom-margin {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.duration {
  margin-left: 0.25rem;
  font-weight: 600;
  margin-right: 0.25rem;
}

.custom-text-gray {
  --tw-text-opacity: 1;
  color: rgb(208 213 221 / var(--tw-text-opacity));
}

.custom-program-container {
  --tw-bg-opacity: 1;
  background-color: rgb(242 244 247 / var(--tw-bg-opacity));
  width: 100%;
  overflow-x: auto;
  position: relative;
  scrollbar-width: none;
}

.custom-section {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding: 15px;
  border-radius: 0.5rem;
}

.custom-modal-program {
  position: relative;
  min-width: 220px;
  max-width: 250px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  border-width: 2px;
  border-color:  #eaecf0;
  cursor: pointer;
}

.custom-exercise-library {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  position: relative;
}

.program-card-description-div {
  padding: 12px;
  width: 100%;
}

.image-program {
  width: 100%;
  height: 200px;
  border-top-left-radius: 0.5rem;  
  border-top-right-radius: 0.5rem;
}

.custom-navigation-program-activity {
  width: 100%;
  position: relative;
}

.custom-program-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -50%;
  background-color: rgb(0 0 0 / 0.4);
  border-radius: 9999px;
  cursor:pointer;
}

.custom-font-semibold {
  font-weight: 600;
}

.custom-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(102 112 133 / var(--tw-text-opacity));
}

.custom-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.custom-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(152 162 179 / var(--tw-text-opacity));
}

.custom-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.custom-program-element {
  display: flex;
  justify-content: center;
  gap: 0.625rem;
  padding: 0.75rem;
}

.custom-text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(208 213 221 / var(--tw-text-opacity));
}

.custom-scroll {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 9999px;
}

.custom-active-program {
  --tw-bg-opacity: 1;
  background-color: rgb(127 86 217 / var(--tw-bg-opacity));
}

.custom-nonactive-program {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.flexible-program {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

.custom-arrow {
  cursor: pointer;
  padding: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(214 187 251 / var(--tw-bg-opacity));
  position: absolute;
  top: 50%;
  --tw-translate-y: -50%;
}

.custom-arrow-right {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  right: 0px;
}

.custom-arrow-left {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  left: 0px;
}

.custom-arrow:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(127 86 217 / var(--tw-bg-opacity));
}

.custom-section .custom-image-container {
	position: relative;
	width: 10rem; 
}

.custom-section .image-wrapper {
	position: relative;
}

.custom-section .play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.4); 
	border-radius: 50%; 
	opacity: 0;
	transition: opacity 0.3s;
	z-index: 1;
}

.custom-section .image-wrapper::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0); 
	border-radius: 0.5rem; 
	transition: background-color 0.3s;
}

.custom-section .image-wrapper:hover::after {
	background-color: rgba(0, 0, 0, 0.15);
}

.custom-section .image-wrapper:hover .play-button {
	opacity: 1;
}

.custom-section .custom-image-container img {
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
}

.custom-section .custom-image-container video {
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
}
