.addToReport-container {
  color:  #ffffff;
  width: 251px;
  height: auto;
  user-select: none;

  .close-icon-css {
    position: absolute;
    top: 0.375rem;
    right: 0.625rem;
    cursor: pointer;
  }

  .search-icon-css {
    background-color: white;
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
  }

  .search-icon-css:hover {
    border-color: #ffffff
  }

  .report-data-div {
    max-height: 16rem;
    overflow-y: auto;
    margin-bottom: 0.75rem;

  }

  .report-checkbox-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .save-button-css {
    width: fit-content;
    cursor: pointer;
    border-radius: 0.375rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .save-button-css:hover {
    background-color: #1d2939;
  }

  .new-report-container {
    margin-top: 25px;
    margin-bottom: 0.5rem;
  }

  .report-action-button-css-div {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;

    .report-action-item {
      cursor: pointer;
      border-radius: 0.375rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .report-action-item:hover {
      background-color: #1d2939;
    }
  }
}

.file-upload-button-css {
  margin-top: -0.15rem;
  margin-right: 0.25rem;
}

.report-count-label-css {
  border-style: none;
  border-radius: 9999px;
}
