.createProgramModalPagination .ant-pagination-item-link {
	padding: 4px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

.createProgramModalPagination .ant-pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.createProgramModalPagination .ant-pagination-prev button::after,
.createProgramModalPagination .ant-pagination-next button::before {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	padding: 10px;
	border-radius: 5px;
}

.createProgramModalPagination .ant-pagination-item-ellipsis {
	display: flex !important;
}

.createProgramModalPagination .ant-pagination-item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border: 1px solid #d9d9d9;
	margin-bottom: 0;
	border-radius: 5px;
}

.createProgramModalPagination .ant-pagination-item-active {
	background-color: #eaecf0;
	border: 1px solid #eaecf0;
}

.createProgramModal .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
	background-color: rgb(234, 236, 240);
}

.createProgramModal
	.ant-tabs-card.ant-tabs-top
	> .ant-tabs-nav
	.ant-tabs-tab-active {
	border: none;
	background-color: rgb(242, 244, 247);
}

.createProgramModal
	.createProgramTab
	.ant-tabs-card.ant-tabs-top
	> .ant-tabs-nav
	.ant-tabs-tab-active {
	border: none;
	background-color: rgb(249, 250, 251);
}

.imageUpload input[type='file'] {
	display: none;
}
