.completion-screen-omnirom-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.75rem;
	user-select: none;
	padding-bottom: 20px;
}

.completion-screen-omnirom-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
}

.completion-screen-omnirom-logo-container {
	background-color: #53389e;
	border-radius: 9999px;
	padding: 1.25rem;
	height: 60px;
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.completion-screen-omnirom-title {
	color: #53389e;
	font-size: 1.875rem;
	font-weight: bold;
}

.completion-screen-omnirom-description {
	color: #98a2b3;
	font-size: 1.125rem;
}

.completion-screen-omnirom-spinner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.completion-screen-omnirom-summary {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	height: calc(100vh - 350px);
	width: 60vw;
	overflow-y: auto;
}

.completion-screen-omnirom-next-button {
	background-color: #12b76a !important;
	position: fixed;
	bottom: 50px;
	color: white !important;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.5rem;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
