.upload-instructions {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.upload-placeholder {
	display: flex;
	width: 154px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 0.5rem;
	border-width: 4px;
	border-color: #e5e7eb;
	border-style: dashed;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 600;
	color: #d1d5db;
}

.cover-image-div-css {
    border-top: 1px solid gainsboro;
		border-bottom: 1px solid gainsboro;
		padding: 20px;
		margin-top: 10px;
		flex-wrap: nowrap; 
}

.createProgramModalPagination .ant-pagination-item-link {
	padding: 4px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

.createProgramModalPagination .ant-pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.createProgramModalPagination .ant-pagination-prev button::after,
.createProgramModalPagination .ant-pagination-next button::before {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	padding: 10px;
	border-radius: 5px;
}

.createProgramModalPagination .ant-pagination-item-ellipsis {
	display: flex !important;
}

.createProgramModalPagination .ant-pagination-item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border: 1px solid #d9d9d9;
	margin-bottom: 0;
	border-radius: 5px;
}

.createProgramModalPagination .ant-pagination-item-active {
	background-color: #eaecf0;
	border: 1px solid #eaecf0;
}

.createProgramModal .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
	background-color: rgb(234, 236, 240);
}

.createProgramModal
	.ant-tabs-card.ant-tabs-top
	> .ant-tabs-nav
	.ant-tabs-tab-active {
	border: none;
	background-color: rgb(242, 244, 247);
}

.createProgramModal
	.createProgramTab
	.ant-tabs-card.ant-tabs-top
	> .ant-tabs-nav
	.ant-tabs-tab-active {
	border: none;
	background-color: rgb(249, 250, 251);
}

.custom-image-container {
	position: relative;
	width: 10rem;
}

.image-wrapper {
	position: relative;
}

.play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	opacity: 0;
	transition: opacity 0.3s;
	z-index: 10;
}

.image-wrapper-exercises {
	position: relative;
}

.play-button-exercises {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: opacity 0.3s;
	z-index: 10;
}

.image-wrapper-exercises::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 0.3s;
}

.image-wrapper::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0.5rem;
	transition: background-color 0.3s;
}

.image-wrapper:hover::after {
	background-color: rgba(0, 0, 0, 0.15);
}

.image-wrapper-exercises:hover::after {
	background-color: rgba(0, 0, 0, 0.15);
}

.image-wrapper:hover .play-button {
	opacity: 1;
}

.image-wrapper-exercises:hover .play-button-exercises {
	opacity: 1;
}

.custom-image-container img {
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
}

.custom-image-container video {
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
}

.exercise-filter {
	font-family: Arial, sans-serif;
	color: #7621cc;
	max-width: 850px;
}
.exercise-filter-end-section {
	background-color: #f8f8f8;
	border-radius: 8px;
}

.section {
	padding: 10px;
	border-radius: 8px;
}

.section-title {
	font-weight: 500;
	color: #c1c1c1;
	font-size: 14px;
}

.section .checkbox-group,
.section .radio-group {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.section .radio-group-label,
.section .checkbox-group-label {
	font-weight: 500;
	font-size: 14px;
	color: #667085;
}

.section .ant-checkbox .ant-checkbox-inner::after {
	border: 2px solid #7621cc;
	border-top: 0;
	border-left: 0;
}

.section .ant-checkbox:hover .ant-checkbox-inner::after {
	border: 2px solid #ffffff;
	border-top: 0;
	border-left: 0;
}

.section .ant-checkbox-checked .ant-checkbox-inner {
	background-color: white;
}

.section .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
	background-color: white;
}

.section .ant-radio-wrapper .ant-radio-inner::after {
	background-color: #7621cc;
}

.filter-button-css-item {
	background-color: #eaecf0;
	transition: all 0.3s ease;
	border-radius: 8px;
	border: none;
	font-size: 14px;
	height: auto;
	min-height: 30px;
	text-align: left;
	display: flex;
	justify-content: start;
	white-space: normal;
	word-wrap: break-word;
	overflow-wrap: break-word;
}

.filter-button-css-item:hover {
	background: linear-gradient(
		130.28deg,
		#6941c6 1.45%,
		#54cdf2 138.49%
	) !important;
	background-size: 400% 400%;
	color: white !important;
}

.filter-button-css-item.selected {
	background: linear-gradient(
		130.28deg,
		#6941c6 1.45%,
		#54cdf2 138.49%
	) !important;
	border: 3px solid #bdff00 !important;
	color: white !important;
	position: relative;
}
@keyframes BgAnimation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.button-group-exercise-filter {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 4px;
}
