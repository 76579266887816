.create-report-container {
  margin: 1.5rem;
}
.create-report-container .header-container {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 9px;
  margin-bottom: 1.5rem ;
}
.create-report-container .header-container .magic-band-span-css {
  border-radius: 9999px;
  background-color: #f4ebff;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.header-content-div-css {
  width: 100%;
}

.header-content-div-css .ai-assistant-report-title {
  font-weight: 700
}
.header-content-div-css .ai-assistant-report-description {
  color:  #667085;
}

.create-report-modal-container {
  margin-left: 1.25rem;
  margin-right: 2rem;
}