.posture-dropdown-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.posture-dropdown-section {
	vertical-align: middle;
	padding-right: 0.75rem;
	margin-top: 0.20rem;
}

.posture-container {
	margin: 0rem 1.5rem;
}

.posture-feedback-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 9px;
}

.posture-feedback {
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(244 235 255 / var(--tw-bg-opacity));
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.375rem;
	padding-right: 0.375rem;
}

.posture-ai-assistant {
	width: 100%;
}

.posture-font-bold {
	font-weight: 700;
}

.posture-text-gray-500 {
	--tw-text-opacity: 1;
	color: rgb(102 112 133 / var(--tw-text-opacity));
}

.posture-add-to-reports {
	display: flex;
	justify-content: flex-end;
	gap: 0.5rem;
	padding-top: 0.25rem;
}

.posture-collapse {
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.posture-panel {
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	border-width: 1px;
	--tw-border-opacity: 1;
	border-color: rgb(234 236 240 / var(--tw-border-opacity));
	border-radius: 0.5rem;
	margin-top: 0.5rem;
}

.posture-card-container {
	padding-right: 1rem;
	width: 100%;
}

.posture-card-section {
	border-width: 1px;
	margin-bottom: 10px;
	width: 100%;
	--tw-border-opacity: 1;
	border-color: rgb(234 236 240 / var(--tw-border-opacity));
}

.posture-title-container {
	display: flex;
	gap: 8px;
	justify-content: space-between;
}

.posture-title {
	min-width: 150px;
	--tw-text-opacity: 1;
	color: rgb(102 112 133 / var(--tw-text-opacity));
	font-weight: 700;

	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}

.posture-heading-title-css {
	--tw-text-opacity: 1;
	color: rgb(102 112 133 / var(--tw-text-opacity));
	font-weight: 700;
}

.posture-label-container {
	display: flex;
	align-items: center;
	gap: 8px;
	width: 585px;
}

.posture-label {
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;

	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	width: 100px;
}

.posture-label-text {
	--tw-text-opacity: 1;
	color: rgb(102 112 133 / var(--tw-text-opacity));
}

.posture-sides-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0px;
}

.posture-sides-section {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-left: 0.5rem;
	min-width: 150px;
}

.posture-sides-title {
	--tw-text-opacity: 1;
	color: rgb(102 112 133 / var(--tw-text-opacity));
	font-weight: 700;
}

.posture-side-labels-container {
	display: flex;
	align-items: center;
	height: 100%;
}


.rom-card-container {
	padding-right: 1rem;
	width: 100%;
}

.posture-side-labels-section {
	border-left-width: 1px;
	border-color: #eaecf0;
	margin-top: 0.125rem;
	height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 86px;
}

.custom-posture-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px; 
  overflow: hidden; 
}

.posture-card-container .ant-table-thead th {
  border-right: none !important;
  padding: 8px 16px !important;
  font-weight: 500;
}

.posture-card-container .ant-table-tbody td {
	margin-bottom: 20px;
	border-bottom: none !important;
  padding: 4px 0px !important;
}

.posture-card-container .ant-table-tbody > tr > td {
	padding: 0px 0px 8px 0px !important;
}

.posture-card-container .ant-table-tbody > tr:hover > td {
  background: transparent !important;
}

.posture-side-labels-title {
	padding-bottom: 0.25rem;
	--tw-text-opacity: 1;
	color: rgb(102 112 133 / var(--tw-text-opacity));
}
