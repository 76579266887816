.emoji {
	display: flex;
	gap: 0.5rem;
}

.emoji-text {
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.emoji-1 {
	--tw-text-opacity: 1;
	color: rgb(6 174 212 / var(--tw-text-opacity));
}

.emoji-2 {
	--tw-text-opacity: 1;
	color: rgb(115 226 163 / var(--tw-text-opacity));
}

.emoji-3 {
	--tw-text-opacity: 1;
	color: rgb(250 197 21 / var(--tw-text-opacity));
}

.emoji-4 {
	--tw-text-opacity: 1;
	color: rgb(243 135 68 / var(--tw-text-opacity));
}

.emoji-5 {
	--tw-text-opacity: 1;
	color: rgb(246 61 104 / var(--tw-text-opacity));
}

.spinner {
	justify-content: center;
	align-items: center;
	padding: 0.5rem;
	display: flex;
}

.select-answer {
	margin-bottom: 0.25rem;
}

.score {
	display: flex;
	height: fit-content;
	align-items: center;
	gap: 0.25rem;
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
	padding-bottom: 0.25rem;
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(18 183 106 / var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
}

.question {
	height: 2rem;
	font-weight: 700;
	line-height: 1.25rem;
}

.flex-between {
	justify-content: space-between;
	display: flex;
}

.empty {
	--tw-bg-opacity: 1;
	background-color: rgb(249 250 251 / var(--tw-bg-opacity));
	padding: 2.5rem;
	margin: 0px;
}

.custom-flex-col {
	display: flex;
	flex-direction: column;
}

.custom-mb {
	margin-bottom: 1rem;
}

.custom-surveyTitle-container {
	gap: 0.5rem;
	padding: 0.5rem;
	display: flex;
}

.total-score {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: center;
}

.survey-container {
	--tw-bg-opacity: 1;
	background-color: rgb(249 250 251 / var(--tw-bg-opacity));
	margin-bottom: 0.5rem;
	padding: 15px;
	border-radius: 0.375rem;
}

.survey-total-score {
	display: flex;
	align-items: center;
	height: fit-content;
	gap: 0.25rem;
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-bottom: 0.25rem;
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(18 183 106 / var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
}
