.single-result {
	background: rgb(166, 126, 255);
	background: linear-gradient(130deg,
			rgba(166, 126, 255, 0.8421743697478992) 0%,
			rgba(89, 101, 255, 0.8421743697478992) 100%);
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	top: 0;
}

.single-result-container {
	width: 80%;
	text-align: center;
}

.result-title {
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	max-width: 60%;
	margin: 10px auto;
	background: #03c852;
	border-radius: 50px;
	padding: 10px;
}

.result-title.invalid,
.result-number.invalid {
	background: #ff01ba;
}

.result-subtitle {
	color: #fff;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 700;
	font-size: 32px;
	text-transform: uppercase;
	margin: 0;
}

.result-subtitle.invalid {
	font-size: 16px;
}

p.result-text {
	color: #fff;
	max-width: 30%;
	margin: 15px auto;
	font-size: 17px;
}

.result-number {
	font-size: 25px;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	margin: 0 0 0 15px;
}

@media screen and (max-width: 1080px) {
	p.result-text {
		max-width: 50%;
		font-size: 14px;
	}

	.result-title,
	.result-number {
		font-size: 16px;
		max-width: 60%;
	}
}

@media screen and (max-width: 768px) {
	.single-result-container img {
		width: 200px;
	}

	.result-title {
		font-size: 40px;
	}

	.result-subtitle {
		font-size: 15px;
	}

	p.result-text {
		max-width: 70%;
		font-size: 12px;
	}

	.result-title,
	.result-number {
		font-size: 16px;
		max-width: 60%;
	}
}

@media screen and (max-width: 550px) {
	.single-result-container img {
		width: 150px;
	}

	.result-title {
		font-size: 30px;
	}

	p.result-text {
		max-width: 70%;
		font-size: 12px;
	}

	.result-title,
	.result-number {
		font-size: 16px;
		max-width: 80%;
	}
}

.single-result .btn-solid-red {
	background: #E31B54;
}

.single-result .btn-solid-green,
.single-result .btn-solid-red {
	border-radius: 0 !important;
	border: none !important;
	padding: 5px 10px 3px 10px;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	font-weight: 700;
	font-size: 14px;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.single-result button.btn-solid-red:hover {
	background: #f12760;
}

.rom-header {
	background: #333;
	width: 100%;
	height: 30px;
	position: absolute;
	top: 0;
	z-index: 101;
	text-align: right;
	border-left: 1px solid #5d5b5b !important;
	vertical-align: bottom;
}

.rom-header button.btn-fullscreen,
.rom-header button.btn-invert-cam,
.rom-header button.btn-switch-video,
.ant-btn-circle.ant-btn-lg {
	background: none !important;
	border: none !important;
	padding: 2px 2px 2px 10px !important;
	height: auto;
	width: auto;
	min-width: 0;
	margin-right: 10px;
	border-left: 1px solid #5d5b5b !important;
	border-radius: 0;
	vertical-align: top;
}

.rom-header-left-wrapper button.btn-icon.btn-menu {
	position: relative;
}

.button-icon.discard img {
	width: 15px;
	height: 14px;
	position: relative;
	top: -3px;
	left: 1px;
}
