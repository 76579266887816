.posture-medical-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.posture-header-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.posture-header-title {
	font-size: 25px;
	color: #6941c6;
	font-weight: 700;
}

.posture-header-subtitle {
	font-size: 18px;
	color: #98a2b3;
	font-weight: 300;
}

.posture-scan-container {
	margin-top: 1rem;
}

.posture-button-container {
	margin: 1rem 0rem 1rem 0rem;
	width: 595px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	gap: 0.5rem;
}

.posture-button-back {
	margin-top: 0.5rem;
	height: 48px;
	width: 280px;
	background: #fff;
	color: #000;
	border: 1px solid #d0d5dd;
}

.posture-button-save {
	height: 48px;
	background: #6941c6;
	color: #fff;
}
