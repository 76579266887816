.bulk-invite-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: center;
}

.bulk-invite-text {
  color: gray;
}

.bulk-invite-text strong {
  color: #4a4a4a;
}

.bulk-invite-download {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #4a4a4a;
}

.bulk-invite-uploading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 182px;
  text-align: center;
}

.bulk-invite-drag-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  text-align: center;
  color: #888;
  font-size: 18px;
}

.bulk-invite-file-uploaded {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 182px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 9px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.bulk-invite-file-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: bold;
}

.bulk-invite-actions {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.default-invite-page .ant-button {
	border: 1px solid #eaecf0;
}

.default-invite-page .ant-btn-icon-only, .ant-btn-lg {
	border-width: 1px !important;
}
