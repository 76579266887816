.body-shape-front-css{
	position: relative;
	margin-bottom: 12px;
}

.hotspot-circle-selection {
  position: absolute;
	width: 20px;
	height: 20px;
	background: rgba(156, 163, 175, 0.38);
	border-radius: 50%;
	position: absolute;
	cursor: pointer;
}
.hotspot-circle-selection:hover {
	background: #ff00ba;
}
.hotspot-circle-selection.active {
	background: #ff00ba;
}
.hotspot-circle-selection.saved {
	background: #00ff2b;
}
.hotspot-circle-selection.active:after {
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 3px solid #ff00ba;
	position: absolute;
	top: -5px;
	left: -5px;
	animation: pulse 2s ease 0s infinite;
}

@keyframes pulse {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	80% {
		opacity: 0;
		transform: scale(2);
	}
	100% {
		opacity: 0;
		transform: scale(3);
	}
}

.hotspot-circle-selection:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}