.form-div {
  text-align: center;
  width: 474px;
  display: inline-block;
}

.form-container {
  background: #eaecf0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 1px;
  margin-top: 20px;
  border-radius: 6px;
  gap: 20px;
}