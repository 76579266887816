.custom-col {
	border-style: dashed;
	border-width: 2px;
	border-color: #e5e7eb;
	border-radius: 0.375rem;
	padding: 8rem;
	cursor: pointer;
}
.flexible-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.icon-image {
	height: '50px';
	width: '50px';
	color: 'gray';
}

.custom-text {
	font-family: 'inter', sans-serif;
	text-align: center;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.25rem;
	letter-spacing: normal;
	color: #718096;
}

.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.flex-center {
	display: flex;
	align-items: center;
}

.custom-border {
	border-color: #d0d5dd;
	border-radius: 0.5rem;
	width: 'auto';
	height: 315px;
}

.absolute-bottom-left {
	position: absolute;
	bottom: 0px;
	left: 0;
	width: 100%;
	text-align: center;
	border-radius: 0.25rem;
	color: #ffffff;
	padding: 0.25rem;
	background-color: rgba(0, 0, 0, 0.49);
}

.activity-stream-label {
	font-family: 'inter', sans-serif;
	font-weight: 600;
	font-size: 17px;
	line-height: 17px;
	letter-spacing: normal;
	text-align: left;
	margin-bottom: 2px;
}

.custom-label {
	font-family: 'inter', sans-serif;
	font-weight: 600;
	line-height: 17px;
	letter-spacing: normal;
	text-align: left;
	margin-bottom: 0.5rem;
}

.scan-image-modal .ant-modal-content {
	padding: 0;
}
