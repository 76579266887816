.custom-col{
  border-style: dashed;
  border-width: 2px;
  border-color: #e5e7eb;
  border-radius: 0.375rem;
  padding: 8rem;
  cursor: pointer;
}
.flexible-modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-image{
  height: "50px";
  width: "50px";
  color:"gray"
}

.custom-text {
  font-family: 'inter', sans-serif;
  text-align: center;
  font-size: 1rem; 
  font-weight: normal;
  line-height: 1.25rem; 
  letter-spacing: normal;
  color: #718096; 
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.flex-center {
  display: flex;
  align-items: center;
}

.custom-border {
  border-color: #d0d5dd;
  border-radius: 0.5rem;
  width: "auto";
  height: 315px
}

.absolute-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  border-bottom-left-radius: 0.25rem;
  color: #ffffff;
  padding: 0.25rem;
  background-color: rgba(0, 0, 0, 0.49);
}

.activity-stream-label {
  font-family: 'inter', sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 2px;
}

.custom-label {
  font-family: 'inter', sans-serif;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0.5rem;
}
.add-more-results-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.add-button-css {
  display: flex;
  gap: 8px;
  color:#667085;
  margin-bottom: 8px;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 8px;
  padding-bottom: 4px;
  border-radius: 8px;
}

.add-button-css:hover {
  background-color: #f2f4f7;
}
.add-button-label {
  font-weight: 600;
  font-size:  14px;
  line-height:  20px;
  margin-top: 2px;
}

.report-content-main-div {
  font-weight: 600;
  padding: 24px;
}

.report-info-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
  margin-top: -28px;
}
.report-li-div {
  display: grid;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
  line-height: 28px;
}
.report-info-value {
  font-weight: 600;
}
.report-user-name-label {
  font-weight: 400;
}
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.tabs-container {
  margin-top: 12px;
  border-color: inherit;
  text-align: start;
}
