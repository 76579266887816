.rehab .result-record {
	background: #fff;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	top: 0;
	z-index: 1000;
	overflow-y: auto;
}

.rehab .ant-switch{
	background-color: rgba(0, 0, 0, 0.25);
}

.rehab .ant-switch.ant-switch-checked{
	background-color: #6739b7;
}

.result-record .container {
	width: 100%;
	height: 40%;
	text-align: center;
}

.result-record .result-record-content {
	max-width: 50%;
	background: #fff;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	padding: 25px;
	margin: 0 auto;
	padding: 15px 25px 25px;
}

h1.result-record-title {
	font-weight: 700;
	font-size: 23px;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	color: #fff;
	margin-top: 25px;
	text-transform: uppercase;
}
