.progress-container {
	height: 720px;
	z-index: 102;
	position: absolute;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	top: 0;
}

.progress-container p {
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 700;
	font-size: 16px;
	color: #ffffff;
	margin: 10px;
}

.progress {
	padding: 6px;
	border-radius: 30px;
	background: rgba(0, 0, 0, 0.25);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
		0 1px rgba(255, 255, 255, 0.08);
	width: 50%;
}

.progress-bar-upload {
	height: 18px;
	border-radius: 30px;
	background-image: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.3),
		rgba(255, 255, 255, 0.05)
	);
	transition: 0.4s linear;
	transition-property: width, background-color;
}

.progress-moved .progress-bar-upload {
	width: 0%;
	background-image: linear-gradient(
		to right,
		rgba(166, 126, 255, 1),
		rgba(89, 101, 255, 1)
	);
}

