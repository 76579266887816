.bulk-invite-error-container {
  color: black;
}

.close-icon {
  cursor: pointer;
  font-size: 1.2em;
  color: black;
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.x-close-icon {
  stroke: gray;
}

.error-message {
  margin-bottom: 10px;
}

.error-table {
  width: 100%;
  border-collapse: collapse;
}

.error-table th,
.error-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
