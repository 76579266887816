.true-to-form-label {
	margin-top: 30px;
	color: #53389e;
	width: 238px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #bdff00;
	border-bottom-right-radius: 9999px;
	border-top-right-radius: 9999px;
	font-size: 16px;
	font-weight: 600;
	animation: slideIn 1s ease-out forwards;
	cursor: pointer;
}

@keyframes slideIn {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.true-to-form-close {
	position: absolute;
	top: 15px;
	right: 15px;
	padding: 10px;
	border-radius: 9999px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #bdff00;
	cursor: pointer;
}
