.timer {
	z-index: 1;
}

.timer.center {
	bottom: calc(50% - 50px);
	right: calc(50% - 50px);
}

.timer.centerDown {
	bottom: 5px;
	right: calc(50% - 600px);
}

.timer.bottomRight {
	bottom: 0;
	right: 0;
}

.timer.topRight {
	top: 60px;
	right: 20px;
}
.timer.bottomLeft {
	bottom: 60px;
	left: 20px;
}

.timer.topleft {
	top: 60px;
	left: 20px;
}

.timer.inbar {
	max-width: 62%;
	z-index: 130;
}

.timer svg path {
	stroke: #fff;
}

.timer svg g g g g g {
	fill: #fff;
}
