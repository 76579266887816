@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
html,
#demo,
.App {
	height: 100%;
}
body {
	margin: 0;
	height: 100%;
}
#demo {
	background: rgb(166, 126, 255);
	background: linear-gradient(
		130deg,
		rgba(166, 126, 255, 1) 0%,
		rgba(89, 101, 255, 1) 100%
	);
}

button.btn-solid-green:hover {
	border: 2px solid #31d373;
	background: #31d373;
}
button.btn-outline:hover {
	background: #6823c0;
	border-color: #6823c0;
}
.btn-solid-purpple {
	border-radius: 7px;
	padding: 8px;
	background: #6823c0;
	border: 2px solid #6823c0;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
}
button.btn-solid-purpple:hover {
	background: #9547fc;
	border-color: #9547fc;
	color: #fff;
}

.result-record-screen {
	height: 80vh;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

canvas {
	width: 100%;
}
.coach-container {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	max-width: 1280px;
	max-height: 720px;
}

.logo-image {
	bottom: 40px;
	left: 40px;
	position: absolute;
	z-index: 2;
}

.main-section-content {
	position: relative;
	z-index: 1;
	background: var(--menu-item-active);
	overflow: hidden;
}

.full-screen-view {
	background: linear-gradient(130deg, #6435cdf2 0%, #575ebdf2 100%);
	max-width: 100%;
	width: 100%;
	height: 100%;
	max-height: 100%;
}

.normal-screen-view {
	background: var(--menu-item-active);
	max-width: 1280px;
	width: 100%;
	height: 720px;
	max-height: 720px;
}

.overlay-container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}
.main-video {
	max-width: 1280px;
	width: 100%;
}
canvas.overlay-canvas {
	width: 100%;
	height: 100%;
}
.overlay-coach {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	overflow: hidden;
}
.overlay-top {
	background: rgb(0, 0, 0);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.7) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	z-index: 101;
	position: absolute;
	height: 85px;
}
.overlay-bottom {
	background: rgb(0, 0, 0);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.7) 100%
	);
	z-index: 101;
	position: absolute;
	width: 100%;

	position: absolute;
	bottom: 0;
	text-align: right;
}
.overlay-top > svg {
	width: 100%;
	display: block;
	height: 85px;
	margin: 0 auto;
}
.overlay-bottom svg {
	width: 98%;
	margin-bottom: 20px;
}
h1.exercise-desc {
	position: absolute;
	top: 0;
	left: 37%;
	color: #fff;
	font-size: 20px;
	margin-top: 20px;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 400;
}

.overlay-content {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 50%;
}
.overlay-content.smaller .instruction {
	-webkit-transition: width 0.5s ease-in-out;
	-moz-transition: width 0.5s ease-in-out;
	-o-transition: width 0.5s ease-in-out;
	transition: width 0.5s ease-in-out;
	width: 35%;
}

.instruction-video {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
}

@media screen and (max-width: 1024px) {
	h1.exercise-desc {
		font-size: 15px;
	}
}
@media screen and (max-width: 768px) {
	h1.exercise-desc {
		font-size: 13px;
		margin-top: 15px;
	}
	.overlay-content {
		bottom: 47px;
	}
}
@media screen and (max-width: 480px) {
	h1.exercise-desc {
		font-size: 11px;
	}
	.overlay-top svg {
		height: 65px;
	}
	.overlay-content {
		bottom: 47px;
	}
}
.overlay-bottom circle {
	cx: 107;
}
.estimator-template {
	background: inherit;
	max-width: 1280px;
	max-height: 720px;
	margin: 0 auto;
}
.btn-outline.replay {
	position: absolute;
	z-index: 105;
	top: 55px;
	left: 25px;
}
img.icon-play {
	width: 20px;
	vertical-align: middle;
	margin-right: 5px;
}
button.btn-icon.btn-menu {
	bottom: 20px;
	position: absolute;
	left: 16px;
	border: none;
	z-index: 1004;
}
img.icon-menu {
	width: 20px;
	vertical-align: middle;
	margin-right: 5px;
}
button.btn-icon.btn-menu:hover i.material-icons {
	color: rgba(255, 255, 255, 0.5) !important;
}
.main-menu {
	background: #642cb9e3;
	width: 100%;
	max-width: 430px;
	height: 100%;
	z-index: 1006;
	position: absolute;
	left: -430px;
	transition: 850ms;
	overflow-y: auto;
}
.main-menu.active {
	transition: 550ms;
	left: 0;
	top: 0;
}
.overlay-menu {
	width: 100%;
	height: 100%;
	background: #000000a1;
	position: relative;
	z-index: 1004;
	overflow: hidden;
	display: none;
}
.overlay-menu.active {
	display: block;
}
ul.exercise-list {
	list-style: none;
	padding: 0 0 0 0;
}
ul.exercise-list li {
	font-size: 14px;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.5);
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	cursor: pointer;
	padding: 10px 20px 10px 40px;
	display: flex;
	justify-content: space-between;
	line-height: 26px;
}
.exercise-list .md-icon {
	vertical-align: middle;
	padding-right: 6px;
}
.btn-menu svg.md-icon {
	fill: #fff;
}
svg.exercise-check.md-icon {
	fill: rgba(255, 255, 255, 0.5);
}
@media only screen and (min-width: 1200px) {
	ul.exercise-list li svg.md-icon.compare-icon {
		display: none;
	}
	ul.exercise-list li:hover svg.md-icon.compare-icon {
		display: initial;
	}
}
svg.md-icon:hover {
	fill: rgba(255, 255, 255, 0.5);
}
ul.exercise-list li.primary-links {
	display: block;
	color: #fff;
}
ul.exercise-list .menu-subtitle {
	font-weight: 600;
	cursor: default;
	margin-top: 25px;
	color: rgba(255, 255, 255, 0.5);
}

ul.exercise-list .menu-subtitle:hover {
	background: transparent;
}

ul.exercise-list li:hover {
	background: rgba(255, 255, 255, 0.07);
}
ul.exercise-list li.done {
	color: #fff;
}
.bar-container {
	background: rgba(255, 255, 255, 0.2);
	width: 100%;
	height: 5px;
}
a.btn-play-pause {
	position: absolute;
	right: 25px;
	bottom: 70px;
	color: #fff;
	z-index: 140;
}
.rehab .videoRecord {
	height: 100%;
	width: 100%;
}
.rehab .coach-container.fullscreen,
.rehab .estimator-template.fullscreen {
	background: inherit;
	max-width: 100%;
	max-height: 100%;
}
.rehab .fullscreen .videoRecord {
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	height: 100%;
}
.rehab .fullscreen .main-video {
	max-width: 100%;
	width: 100%;
	height: 100%;
}

.iconModalInativity {
	float: left;
	margin-right: 16px;
	display: inline-block;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
}
.rehab-container .info-instruction {
	background: none;
	position: relative;
	color: #ffffff;
}
.rehab-container .info-instruction .ant-row .ant-col {
	padding: 10px 0;
}
.rehab-container .info-instruction h1 {
	color: #ffffff;
}

.ant-drawer-content {
	background-color: #00000000;
}

.rehab-info-wraper .ant-typography {
	color: white !important;
}

.rehab-info-wraper .ant-statistic-title {
	color: rgba(255, 255, 255, 0.5) !important;
}

.rehab-info-wraper .ant-statistic-content {
	color: white !important;
}
