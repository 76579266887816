.modal-style {
  user-select: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.no-select-margin-top {
  user-select: none;
  margin-top: 24px;
}

.spinner {
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  display: flex;
}

.status {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
}

.status-improving {
  background-color: rgb(22 179 100 / var(--tw-bg-opacity));
}

.status-worsening {
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.status-nochange {
  background-color: rgb(250 197 21 / var(--tw-bg-opacity));
}

.painlevel {
  display: flex;
  gap: 0.5rem;
}

.painlevel-emoji {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}

.emoji-1 {
  --tw-text-opacity: 1;
  color: rgb(6 174 212 / var(--tw-text-opacity));
}

.emoji-2 {
  --tw-text-opacity: 1;
  color: rgb(8 116 67 / var(--tw-text-opacity));
}

.emoji-3 {
  --tw-text-opacity: 1;
  color: rgb(22 179 100 / var(--tw-text-opacity));
}

.emoji-4 {
  --tw-text-opacity: 1;
  color: rgb(115 226 163 / var(--tw-text-opacity));
}

.emoji-5 {
  --tw-text-opacity: 1;
  color: rgb(253 226 114 / var(--tw-text-opacity));
}

.emoji-6 {
  --tw-text-opacity: 1;
  color: rgb(250 197 21 / var(--tw-text-opacity));
}

.emoji-7 {
  --tw-text-opacity: 1;
  color: rgb(247 178 122 / var(--tw-text-opacity));
}

.emoji-8 {
  --tw-text-opacity: 1;
  color: rgb(243 135 68 / var(--tw-text-opacity));
}

.emoji-9 {
  --tw-text-opacity: 1;
  color: rgb(246 61 104 / var(--tw-text-opacity));
}

.emoji-10 {
  --tw-text-opacity: 1;
  color: rgb(227 27 84 / var(--tw-text-opacity));
}

.emoji-11 {
  --tw-text-opacity: 1;
  color: rgb(192 16 72 / var(--tw-text-opacity));
}

.status-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}


.program-container {
  padding: 0.5rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.video-id {
  position: relative;
  border-radius: 0.375rem;
  padding-left: 0.75rem;
  padding-bottom: 0.75rem;
}

.custom-modal {
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 0.25rem;
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.custom-name-container {
  background-color: #374151;
  color: #ffffff;
  padding: 0.5rem;
  margin-top: -0.25rem;
  position: relative;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  display: flex;
  justify-content: space-between;
}

.flexible{
  display: flex;
  align-items: center;
  justify-content: center;
}

.pain-level-icon-css {
  margin-left: 8px;
  margin-top: -2px;
}