.common-searches-main-container {
  margin-left: 40px;
  margin-top: 40px;
}

.common-searches-container {
  cursor: pointer;
}

.common-div-css {
  margin-top: 15px;
}
