.slider-card {
	text-align: center;
	margin-top: 16px;
	cursor: pointer;
	border-radius: 20px;
	background-color: transparent;
}

.pain-subcontainer {
	display: flex;
	gap: 20px;
	justify-content: center;
}

.slide-container {
	position: relative;
	perspective: 1000px;
	overflow: hidden;
	border-radius: 20px;
	border: 2px solid #7184DB33;
}

.slide-container.selected {
  border: 4px solid #BDFF00;
}

.slide-container-front {
	box-shadow: 0 0 10px rgba(184, 184, 184, 0.5);
	position: relative;
	border-radius: 20px;
	height: 450px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.slide-container-front-title {
	position: absolute;
	width: 100%;
	bottom: 0;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	height: 84px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 20px;
	text-align: center;
	padding: 20px;
	font-weight: 300;
}

.slide-container-back {
	box-shadow: 0 0 10px rgba(184, 184, 184, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	cursor: pointer;
	transform: translateY(100%);
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
	opacity: 0;
}

.slide-container-back-content {
	position: absolute;
	padding: 20px;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.slide-container-option {
	padding: 22px;
	color: white;
	font-size: 18px;
	font-weight: 600;
}

.slide-container:hover .slide-container-back {
	transform: translateY(0);
	opacity: 1;
}

.functional-container {
	margin-left: auto;
	margin-right: auto;
	margin-top: 16px;
}

.functional-sub-container {
	margin-bottom: 2.5rem;
	text-align: center;
}

.functional-card-div {
	display: flex;
	justify-content: center;
}

.back-button-div-css {
	margin-top: 1rem;
	display: flex;
	justify-content: center;
	gap: 1rem;
}

.back-button-inside-css {
	background-color: #eaecf0;
	color: #53389e;
	border-radius: 9999px;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5rem;
}