.program-main-container {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
 
  .header-ai-assistant {
    margin-top: 0.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 9px;
 
    .image-icon-wrappaer {
      border-radius: 9999px;
      background-color: #f4ebff;
      padding: 0.25rem 0.375rem;
    }
 
    .header-text-admin {
      margin-bottom: 8px;
    }
  }
 
  .create-program-label-div {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    height: 2.75rem;
    border: 2px dashed #d0d5dd;
    color: #98a2b3;
    font-weight: 600;
    border-radius: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
 
  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }
}

.lower-content-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
}
 
.ai-assistant-container-css {
  user-select: none;
  position: fixed;
  width: 100%;
  z-index: 50;
  margin-right: 0.90rem;
}
 
.ai-rendered-main-conatiner {
  user-select: none;
 
  .new-recent-input-css {
    margin-top: 8rem;
  }
 
  .new-recent-no-input-css {
    margin-top: 5rem;
  }
 
  .rendered-component-css {
    margin-bottom: 1.25rem;
  }
 
  .no-component-found-div {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    color: #6941c6;
    margin-left: 2rem;
    margin-top: 2.5rem;
  }
}
 
.rom-capture-container {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
 
.rom-capture-result-conatiner {
  user-select: none;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
 
  .rom-capture-result-panel-container {
    background-color: #ffffff;
    border-width: 1px;
    border-color: #eaecf0;
    border-radius: 0.75rem;
    margin-top: 8px;
 
    .tabs-css {
      border-radius: 8px;
    }
  }
}
 
.rom-result-ai-assistant-true {
  background-color: #ffffff;
}
 
.rom-result-ai-assistant-false {
  background-color: #f9fafb;
}
 
.ai-assistant-search-header {
  user-select: none;
  position: fixed;
  width: 100%;
  z-index: 50;
  margin-right: 14.4px;
}
 
.ai-assistant-rendered-component {
  user-select: none;
 
  .ai-assistant-activetab {
    margin-bottom: 20px;
  }
 
  .no-component-found-div {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    color: #6941c6;
    margin-left: 2rem;
    margin-top: 2.5rem;
  }
}
 
.new-recent-input-true {
  margin-top: 128px;
}
 
.new-recent-input-false {
  margin-top: 80px;
}
