.rom-medical-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.header-container-rom {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.header-title {
	font-size: 25px;
	color: var(--status-count);
	font-weight: 700;
}

.header-subtitle {
	font-size: 18px;
	color: #98a2b3;
	font-weight: 300;
}

.rom-container {
	height: 720px;
}

.rom-omni-button-container {
	margin: 1rem 0rem 1rem 0rem;
	width: 595px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	gap: 0.5rem;
}

.rom-main-container {
	display: flex;
	margin-bottom: 1.25rem;
	margin-top: 1.25rem;
}

.rom-inner-container {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	gap: 1rem;
	height: 350px;
	width: 750px;
}

.rom-card {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: white;
	border-radius: 1.5rem;
	flex: 1;
}

.rom-image {
	width: 100%;
	max-width: 50%;
	border-top-left-radius: 1.5rem;
	border-top-right-radius: 1.5rem;
	margin-bottom: 0.5rem;
}

.rom-info {
	padding: 1.5rem;
}

.rom-text {
	font-weight: 300;
	font-size: 16px;
	color: #53389e;
	margin-top: 1.5rem;
	line-height: 24px;
}

.rom-btn-container {
	width: 100%;
	padding-top: 0.5rem;
}

.rom-start-session-btn {
	margin-top: 1.25rem;
}

.rom-btn-text {
	font-weight: 700;
	font-size: 18px;
	color: #53389e;
	line-height: 38px;
}

.rom-back-btn {
	margin-top: 0.5rem;
	border-radius: 9999px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	line-height: 1.5rem;
	background-color: #e5e7eb;
	color: #53389e;
}
@media (min-width: 768px) {
	.rom-card {
		flex-direction: row;
	}
}

@media (min-width: 768px) {
	.rom-image {
		border-top-left-radius: 1.5rem;
		border-bottom-left-radius: 1.5rem;
		width: 50%;
	}
	.rom-info {
		width: 60%;
		padding: 2rem;
	}
	.rom-text {
		font-size: 20px;
		line-height: 30px;
	}
}

.rom-opacity {
	opacity: 0.5;
}

.rom-skip-btn {
	border-radius: 9999px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	line-height: 1.5rem;
}
