@keyframes slideInLeftToRight {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slideOutRightToLeft {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(100%);
	}
}

.animated-text {
	font-size: 4rem;
	color: #5827ac;
	display: inline-block;
	min-height: 100px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: rgb(255 255 255 / 0.7);
	width: 75%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	padding-left: 1.25rem;
	padding-right: 6.25rem;
}

.animated-text-wrap {
	background-color: #7e6fd8;
	width: 75%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	padding-left: 1.25rem;
	padding-right: 6.25rem;
}

.bodyside-title-text {
	font-size: 4rem;
	color: #5827ac;
	display: inline-block;
	min-height: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: #7e6fd8;
}

#root div.animation-wrapper div,
#root div.animation-wrapper p {
	font-weight: 800;
	font-family: Montserrat;
}

.movement-title-text {
	font-size: 6rem;
	color: white;
	display: inline-block;
	min-height: 100px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: #7e6fd8;
}

.inner-box {
	height: auto;
	background-color: white;
	width: 1.25rem;
	margin-right: 1.25rem;
}

.animation-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.slide-in {
	animation: slideInLeftToRight 1s ease-out forwards;
}

.slide-out {
	animation: slideOutRightToLeft 1s ease-in forwards;
}
