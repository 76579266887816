.button-container {
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	align-items: center;
}

.pt-container {
	display: flex;
	padding: 0.4rem;
	justify-content: center;
	align-items: center;
	border-radius: 0.375rem;
	background-color: #f3f4f6;
}

.line-seperation {
	background-color: rgb(152 162 179 / var(--tw-bg-opacity)) /* #98a2b3 */;
	color: rgb(152 162 179 / var(--tw-bg-opacity)) /* #98a2b3 */;
}

.text-pt {
	color: #667085;
}

.assigned-pt-title {
	display: flex;
	justify-content: center;
	align-items: center;
}

.main-container {
	display: flex;
	flex-direction: column;
	row-gap: 0.75rem;
}

.flex-container-pt-list {
	display: flex;
	align-items: center;
	margin-bottom: 2px;
	padding: 0.5rem;
}

.flex-container-pt-list:hover {
	background-color: #e2e8f0;
	border-radius: 0.5rem;
}

.custom-avatar-pt-profile {
	color: #fff;
	font-size: 20px;
	display: inline-flex;
	align-items: center;
}
