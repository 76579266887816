.user-popover-content {
  width: 300px;
}

.search-input {
  margin-bottom: 10px;
}

.user-avatar {
  cursor: pointer;
}

.user-list-item {
  transition: background-color 0.3s ease;
}

.user-list-item:hover {
  background-color: #f5f5f5; 
  cursor: pointer;
}

.ant-list-item-meta-avatar {
	margin-top: 4px;
}

.add-to-patient-div .ant-pagination-item-link {
	padding: 4px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

.add-to-patient-div .ant-pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.add-to-patient-div .ant-pagination-prev button::after,
.add-to-patient-div .ant-pagination-next button::before {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	padding: 10px;
	border-radius: 5px;
}

.add-to-patient-div .ant-pagination-item-ellipsis {
	display: flex !important;
}

.add-to-patient-div .ant-pagination-item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border: 1px solid #d9d9d9;
	margin-bottom: 0;
	border-radius: 5px;
}

.add-to-patient-div .ant-pagination-item-active {
	background-color: #eaecf0;
	border: 1px solid #eaecf0;
}
