.activity-padding {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.activity-item {
	height: 100px;
	border-radius: 12px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	margin-bottom: 0.5rem;
	background-color: rgba(213, 217, 235, 1);
	overflow: hidden;
}

.activity-header {
	margin-left: 0;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.colored-bar {
	height: 50px;
	padding: 5px;
	border-top-left-radius: 12px;
	background-color: rgba(105, 65, 198, 1);
}
.margin-auto {
	margin-left: auto;
}
.margin-left {
	margin-left: 2.5rem;
}
.bg-white-custom {
	background-color: #fff;
}
.bg-slate-custom {
	--tw-bg-opacity: 1;
	background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.pt-panel .header-panel.panel {
  border-width: 1px;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
}
.panel-head {
	display: flex;
}
.panel-profile {
	position: absolute;
	margin-top: 0.5rem;
	left: -42px;
	margin-left: 0px;
}

.custom-rounded-full {
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(248 250 252 / var(--tw-bg-opacity));
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.75rem;
	height: 2.75rem;
}

.custom-rounded-inner {
	border-radius: 9999px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.75rem;
	height: 1.75rem;
}

.custom-flex-grow {
	flex-grow: 1;
}

.custom-flex {
	display: flex;
}

.custom-items-center {
	align-items: center;
}

.panel-item {
	width: 100%;
	margin-left: 0.5rem;
}

.custom-panel-details {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
}

.custom-flex-row {
	flex-direction: row;
}

.custom-flex-col {
	display: flex;
	flex-direction: column;
}

.custom-mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.flex-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.25rem;
	padding-left: 0.375rem;
	padding-right: 0.375rem;
	padding-top: 0.125rem;
	padding-bottom: 0.3125rem;
	border-radius: 0.375rem;
	--tw-bg-opacity: 1;
	background-color: rgb(18 183 106 / var(--tw-bg-opacity));
	color: #ffffff;
}

.custom-panel-column-item {
	display: flex;
	flex-direction: column;
}

.custom-font-bold {
	font-weight: 700;
}

.flex-items-center-justify-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-items-center {
	display: flex;
	align-items: center;
}

.flex-items-center-justify-end {
	display: flex;
	align-items: center;
	justify-content: end;
}

.gap-2-mr-2 {
	gap: 0.5rem;
	margin-right: 0.5rem;
}

.body-region {
	--tw-text-opacity: 1;
	color: rgb(74 31 184 / var(--tw-text-opacity));
	--tw-bg-opacity: 1;
	background-color: rgb(235 233 254 / var(--tw-bg-opacity));
	padding: 0.25rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 9999px;
	font-size: 0.75rem;
	line-height: 1rem;
	margin-left: 0.25rem;
}
.dropdown {
	vertical-align: middle;
}
.custom-image-div {
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(241 245 249 / var(--tw-bg-opacity));
	width: 2.75rem;
	height: 2.75rem;
}
.custom-icon-div {
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	width: 1.75rem;
	height: 1.75rem;
}
.custom-mt-3 {
	margin-top: 0.75rem;
}
.custom-button {
	font-size: 0.75rem;
	line-height: 1rem;
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
}

.custom-button-container {
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
	--tw-bg-opacity: 1;
	background-color: rgb(18 183 106 / var(--tw-bg-opacity));
	border-radius: 0.5rem;
	display: flex;
	height: fit-content;
	align-items: center;
	gap: 0.25rem;
	padding-left: 0.375rem;
	padding-right: 0.375rem;
	padding-top: 0.125rem;
	padding-bottom: 0.25rem;
}

.profile-image {
	width: 20px;
	height: 20px;
	object-fit: cover;
	margin-right: 2px;
	border-radius: 50%;
}

.custom-text-gray-blue-500 {
	--tw-text-opacity: 1;
	color: var(--activity-footer-text);
}

.custom-flex-col {
	display: flex;
	flex-direction: column;
}
.custom-flex-flex-row-items-center-justify-between {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.custom-mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.custom-flex-grow .start-session-css {
  background-color: var(--button-color) !important;
}

.custom-space {
	height: 50px;
	padding: 5px;
	border-bottom-left-radius: 0.75rem;
}

.custom-title {
	margin-right: 0.5rem;
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
}

.custom-inner-title {
	margin-right: 0.5rem;
	display: flex;
	flex-direction: column;
}

.custom-padding {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.custom-image {
	--tw-border-opacity: 1;
	border-color: rgb(208 213 221 / var(--tw-border-opacity));
	border-radius: 0.5rem;
}

.popup-message-head {
	align-self: flex-start;
	justify-self: start;
}

.popup-message {
	font-size: 0.75rem;
	line-height: 1.25rem;
	letter-spacing: 0em;
	font-family: 'inter';
	text-align: left;
	padding: 10px;
}

.plain-message {
	word-break: break-all;
	max-width: 100%;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.custom-inline-flex-panel {
	color: #FFF;
	font-size: 14px;
	display: inline-flex;
	align-items: center;
}

.custom-md-outline{
	--tw-text-opacity: 1;
  color: #000;
	height: 1.25rem;
	width: 1.25rem;
}

.custom-md-outline-gray{
	--tw-text-opacity: 1;
  color: rgb(102 112 133 / var(--tw-text-opacity));
	height: 1.25rem;
	width: 1.25rem;
}