.usermenu-container {
  width: 100%;
  height: 100vh;
  overflow: auto;
  align-self: end;
}
.usermenu-subcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #6941c6;
  overflow: auto;
}

.user-menu {
	position: absolute;
	margin-top: 0.5rem;
	left: 22px;
}

.user-menu-content {
	background-color: var(--adminmenu-bg-color);
	height: 100vh;
	width: 20rem;
	display: flex;
  flex-direction: column;
	align-items: stretch;
	overflow-y: auto;
	overflow-x: hidden;
}

.first-ul-item {
  width: 91.666667%;
  margin-left: 1.25rem;
  margin-top: 0.25rem;
}

.li-logo {
  cursor: pointer;
  margin-bottom: 1rem;
	margin-top: 0.75rem;
	margin-left: 1rem;
}

.li-logo:hover {
  background-image: none;
}

.li-user-details-item {
  padding-left: 18px;
  padding-right: 10px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  position: relative;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  align-items: center;
  justify-content: center;
}

.edit-image-icon {
  position: absolute;
  left: 61%;
  top: 4.5rem;
  border-radius: 9999px;
  cursor: pointer;
}

.first-name-label-text {
  font-family: 'Inter';
  font-weight: 600;
  margin-top: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
}

.email-label-text {
  color:  #98a2b3;
  margin-bottom: 0.5rem;
}

.second-ul-item {
  margin-top: auto; 
}

.menu-profile-item {
  padding-left: 0.75rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem; 
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem 
}

.logoutContainer {
  margin-top: auto;
  border-top-width: 2px;
  border-color: var(--mainmenu-bg-color);
  display: flex;
  justify-content: flex-end;
  align-items: center; 
}
.logoutContainer-div {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-bottom: 4px;
}

.logoutText {
  font-weight: 600;
  margin-top: 4px;
  cursor: pointer;
}

.logoutIcon-image {
  cursor: pointer;
  padding: 8px;
  border-radius: 0.375rem;
  margin-top: 4px;
}
.logoutIcon-image:hover {
  background-color: var(--menu-item-active);
}

.user-popup-label-capitalize {
  text-transform: capitalize;
}

.avatar-container .start-session-css {
  background-color: var(--button-color) !important;
}