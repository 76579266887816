.custom-dropdown-menu {
  background-color: black !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-dropdown-menu .ant-dropdown-menu-item {
  color: white !important;
}

.custom-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
}

.header-dropdown-css {
  color: black;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  cursor: pointer;
  box-shadow: none;
}

.custom-dropdown-menu .custom-menu-item {
  display: flex;
  align-items: center;
  padding: 8px 12px; 
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.custom-dropdown-menu .custom-menu-item:hover {
  background-color: #1d4ed8 !important; 
  color: #ffffff; 
}
.custom-dropdown-menu .custom-menu-item.selected-option {
  background-color: #1a1a1a; 
  color: #ffffff;
}

.custom-dropdown-menu .custom-menu-item .flex {
  display: flex;
  align-items: center;
}

.custom-dropdown-menu .custom-menu-item .mr-2 {
  margin-right: 8px; 
}