.readySetGo {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
}

.readySetGo svg path {
	stroke: #fff;
}

.readySetGo svg g g g g g {
	fill: #fff;
}
