.custom-image-container {
	position: relative;
	width: 10rem;
}

.image-wrapper {
	position: relative;
}

.play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	opacity: 0;
	transition: opacity 0.3s;
	z-index: 10;
}

.image-wrapper::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0.5rem;
	transition: background-color 0.3s;
}

.image-wrapper:hover::after {
	background-color: rgba(0, 0, 0, 0.15);
}

.image-wrapper:hover .play-button {
	opacity: 1;
}

.custom-image-container img {
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
}

.custom-image-container video {
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
}
