.rom-template .main-tabs {
  padding: 16px;
  background-color: #f3f4f6;
}

.rom-template .tab-bar {
  margin: 0;
}

.rom-template .no-roms {
  background-color: #f9fafb;
  padding: 40px;
  margin: 0;
}

.rom-template .no-roms-description {
  color: #d1d5db;
}

.rom-template .extra-content {
  margin-right: -16px;
  display: flex;
  align-items: center;
}

.rom-template .select-button {
  border-radius: 0.375rem;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.rom-template .select-button.bg-selected {
  background-color: #10b981;
  color: white;
}

.rom-template .select-button.bg-unselected {
  background-color: #f3f4f6;
}

.rom-template .select-button .check-icon.selected {
  stroke: white;
}

.rom-template .select-button .check-icon.unselected {
  stroke: black;
}
