.pain-assessment .pre-assessment {
  background: none;
}

.pain-assessment .hotspot-circle {
  background: #ccc;
}

.pain-assessment .pre-assessment .containers {
  min-width: 480px;
}

.pain-assessment .hotspot-circle.active {
  background: #ff00ba;
}
.pain-assessment .hotspot-circle.saved {
  background: #00ff2b;
}

.pain-assessment .pre-assessment h2 {
  color: var(--menu-item-active);
}

.pain-assessment svg {
  display: block;
}