.ready-state {
	height: 35px;
	position: absolute;
	z-index: 1;
	top: 30%;
	left: 45%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 1.5;
}

.startin {
	font-family: 'inter';
	font-weight: 400;
	letter-spacing: 0em;
	text-align: center;
	line-height: 27px;
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
}

.recording-state {
	height: 35px;
	position: absolute;
	z-index: 1;
	top: 85%;
	left: 42%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 1.5;
}
.start-state {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 1.5;
	height: 35px;
	position: absolute;
	z-index: 1;
	top: 45%;
	left: 38%;
}
.record-state {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 1.5;
	height: 35px;
	position: absolute;
	z-index: 1;
	top: 75%;
	left: 45%;
}

.replay-state {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 1.5;
	height: 35px;
	position: absolute;
	z-index: 1;
	top: 85%;
	left: 40%;
}
.customText {
	font-weight: normal;
	line-height: 42px;
	letter-spacing: normal;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: center;
}
