.review-modal-css .ant-btn-default {
	color: #6739b7;
	border-color: #6739b7;
	background-color: #fff;
}

.review-modal-css .ant-btn-default:hover {
	color: white !important;
	background: #6739b7 !important;
}

.review-modal-css .ant-btn-default:disabled {
	color: #cfcfcf;
	border-color: #cfcfcf;
	background-color: #f3f3f3;
}

.review-modal-css .ant-btn-default:disabled:hover {
	color: #cfcfcf !important;
	border-color: #cfcfcf;
	background-color: #f3f3f3 !important;
	cursor: not-allowed;
}

.review-modal-css .ant-pagination-item-link {
	padding: 4px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

.review-modal-css .ant-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
}

.review-modal-css .ant-pagination-prev,
.review-modal-css .ant-pagination-next {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
}

.review-modal-css .ant-pagination-item {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #d9d9d9;
	margin-bottom: 0;
	border-radius: 5px;
}

.review-modal-css .ant-pagination-item-active {
	background-color: #eaecf0;
	border: 1px solid #eaecf0;
}

.btn-report-css:hover {
  .stroke-gray-700 {
    color:  #865cc4;
    stroke: #865cc4;
  }
}

.report-preview-title {
	display: flex;
	gap: 8px;
	cursor: pointer;
}

.soap-add-to-report-css {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	margin-right: 36px;
	margin-bottom: 8px;
}